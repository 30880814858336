import format from 'string-format';
import { post } from './webAPI';

export const insertCentralizedLog = (memberCode, person_id_cds, log) => {
    const data = {
        log_element_id: log.log_element_id,
        log_action_id: log.log_action_id,
        log_page: window.location.pathname,
        event_generator: "ua",
        resolution: format("{0}X{1}", window.screen.width, window.screen.height),
        variables: null,
        response_time: 0,
        log_action_type: log.log_action_type,
        element_phrase: log.element_phrase,
        page_phrase: log.page_phrase,
        module_id: log.module_id,
        page_id: log.page_id,
        action_type: log.action_type
      };
    
      let extendedOptions = {
        data: data,
      };
      const url = format(
        process.env.REACT_APP_WEB_API_URL_INSERT_CENTRALIZED_LOG,
        memberCode,
        person_id_cds
      );
      return post(url, extendedOptions);
};




