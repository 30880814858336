import React, { useState } from "react";
import "./Inputs.css";
import isIsraeliIdValid from "israeli-id-validator";

function Inputs(props) {
  const {
    memberCode,
    memberId,
    setMemberCode,
    setMemberId,
    isMemberIdValid,
    setIsMemberIdValid,
    getLanguage,
  } = props;

  const [isOpenMemberCode, setIsOpenMemberCode] = useState(false);

  const onChangeMemberCode = (e) => {
    setMemberCode(e.currentTarget.value);
    setIsOpenMemberCode(false);
  };

  const onChangeMemberId = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");

    setMemberId(onlyNums);
  };

  const onBlurMemberId = (e) => {
    if (memberCode === "9") return setIsMemberIdValid(true);
    let txtId = e.currentTarget.value;

    if (isIsraeliIdValid(txtId)) {
      return setIsMemberIdValid(true);
    }
    return setIsMemberIdValid(false);
  };

  const toggleMemberCode = () => {
    setIsOpenMemberCode(!isOpenMemberCode);
  };

  return (
    <div className="wrapInputs">
      <div className={`firstInput inputGroup  ${!isMemberIdValid && "error"}`}>
        <label className="label">{getLanguage.member_id}</label>
        <input
          onBlur={onBlurMemberId}
          onChange={onChangeMemberId}
          value={memberId}
          className="memberId"
          placeholder={getLanguage.member_id_placeholder}
          maxLength={9}
        ></input>
        <div className="validation">
          {memberId.length === 0
            ? getLanguage.required_validation
            : getLanguage.validation}
        </div>
      </div>
      <div className="secondInput inputGroup">
        <label className="label">{getLanguage.member_code}</label>
        <div className="dropdown">
          <button onClick={toggleMemberCode} className="memberCode">
            {memberCode}
          </button>
          <div
            className={`${
              isOpenMemberCode ? "show" : "hide"
            } memberCodeDropDown`}
          >
            <button onClick={onChangeMemberCode} value="0">
              0
            </button>
            <button onClick={onChangeMemberCode} value="9">
              9
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inputs;
