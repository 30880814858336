import React from "react";
import "./Error.css";
import error from "../../assets/error.png";
import cancel from "../../assets/cancel.png";
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

function Error(props) {
  const { isValid, language, onToggle } = props;
  const onClose = () => {
    onToggle(false);
  };

  return (
    <Modal
      isOpen={isValid}
      className={"wrapError"}
      toggle={(e) => onClose(e)}
    >
      <div onClick={onClose} className="exitButton"  >
        <img src={cancel} alt="" />
      </div>
      <img src={error} alt="" className="imgSuccess" />

      <ModalHeader className={"modalHeader"}>
        <div>{language.title}</div>
      </ModalHeader>
      <ModalBody className={"style.modalBody"}>{language.text}</ModalBody>
      <ModalFooter className={"modalFooter"}>
        <div className="wrapSubmit">
          <button className="submit modal-submit" onClick={onClose}>
          {language.btnText}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default Error;
