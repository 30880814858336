import React from "react";
import "./Success.css";
import success from "../../assets/success.png";

function Success(props) {
  const { isValid, language } = props;

  return (
    <div className={`wrapSuccess ${isValid ? "show" : "hide"}`}>
      <img src={success} alt="" className="imgSuccess" />
      {language === "עברית" ? (
        <>
          <h1 className="titleHebrew">בקשתך להסרה מרשימת התפוצה נשלחה</h1>
          <p>
            נוכל להסיר את הדיוורים לפי תעודת הזהות המצוינת בטופס רק אם הפרטים
            האישיים שמילאת זהים לפרטים הקיימים במערכות מכבי.
          </p>
          <p>
            לתשומת ליבך, ייתכן שיישלחו דיוורים נוספים עד להשלמת הטיפול בבקשה
            בתוך 7 ימי עסקים. במקרה של המשך קבלת הדיוורים לאחר פרק זמן זה – יש
            לפנות למוקד השירות 3555*.
          </p>
        </>
      ) : (
        <>
          <h1 className="titleHebrew">
            Your unsubscribe request has been sent
          </h1>
          <p>
            Your request will be processed within 7 business days and only if
            the personal details you filled in are identical to the details that
            exist at Maccabi.
          </p>
          <p>
            Please note that you might continue to receive additional mailings
            until your request is fully proceeded.
          </p>
          <p>
            If mailings continue after this period, contact the call center at
            *3555
          </p>
        </>
      )}
    </div>
  );
}

export default Success;
