import format from 'string-format';
import { patch, get } from './webAPI';

export const notificationRemove = (params, memberCode, memberId) => {
    const extendedOptions = {
        data: params
      };

      const url = format(process.env.REACT_APP_WEB_API_URL_ONLINE_NOTIFICATION_REMOVE, memberCode, memberId);
      console.log('url',url);
    
      try{
         const result =  patch(url,extendedOptions);
         console.log('result',result);
         return true;
      }
      catch(err){
        
        console.log('err',err );
        return false;
      }
};

export  const  getServerName = async() => {

      const url = process.env.REACT_APP_WEB_API_URL_ONLINE_SERVER_NAME;
      console.log('url',url);
    
      try{
         const result = await  get(url);
         const server_name = result && result.server_name;
         return server_name;
      }
      catch(err){
        
        console.log('getServerName - err',err );
        return '';
      }
};

