import React from "react";
import "./Header.css";
import logo from "../../assets/logo.png";
import logoEnglish from "../../assets/logoEnglish.png";
function Header(props) {
  const { language } = props;
  return (
    <div className="stripe">
      <img
        src={language === "עברית" ? logo : logoEnglish}
        alt="לוגו"
        className="logo"
      />
    </div>
  );
}

export default Header;
