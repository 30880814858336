import axios from 'axios';
import merge from 'lodash/merge';

export const get = (url, extendedOptions) => {
    return call(url, 'GET', extendedOptions);
};

export const post = (url, extendedOptions) => {
    return call(url, 'POST', extendedOptions);
};

export const patch = (url, extendedOptions) => {
    console.log('patch url',url);
    return call(url, 'PATCH', extendedOptions);
};

export const put = (url, extendedOptions) => {
    return call(url, 'PUT', extendedOptions);
};

//Impossible to give name "delete" to function because delete is operator
export const remove = (url, extendedOptions) => {
    return call(url, 'DELETE', extendedOptions);
};


const call = (url, method, extendedOptions) => {
    console.log('call url',url);
    console.log('call extendedOptions',extendedOptions);
    return new Promise((resolve, reject) => {
        const initalHttpData = {
           'Content-Type': 'application/json',
            method: method, // By default it's POST in case you didnt specify anything
            timeout: 30000
        };
        let options = merge(initalHttpData, extendedOptions);

        // Fire the request for the prepared options.
        return axios(url, options)
            .then(response => {
                console.log(`callApi success - esponse.data: ${response.data}`);
                resolve(response.data);
            })
            .catch(error => {
                console.log(`callApi failed - url: ${url}, error: ${error}`);               
                reject(error);
            });
    });
};
