export const HEBREW = {
  page_title: "בקשה להסרה מקבלת דיוורים ישירים ממכבי",
  member_id: "תעודת זהות",
  member_id_placeholder: "ת.ז של מבקש/ת ההסרה",
  member_code: "קוד אזרחות",
  required_validation: "יש להזין ת.ז",
  validation: "ת.ז לא תקינה",
  first_line: "רשימת סוגי דיוור ישיר להסרה",
  second_Line: "ניתן לבחור יותר מאחד",
  children: "מבקש/ת להסיר מרשימת הדיוור גם את ילדיי הקטינים",
  button: "שליחה",
  required_select_all:"יש לבחור לפחות אחד מסוגי הדיוור",
  disclaimer:"זוהי פנייה שירותית בדיוור ישיר ממאגר המידע של מכבי שירותי בריאות, בהתאם למידע שהתקבל ממך כמבוטח/ת. ככל שאינך מעוניין/ת בקבלת הודעות בדיוור ישיר שירותיות, בכלל או מסוג מסוים, באפשרותך לבקש שנסיר את פרטיך מרשימת התפוצה המשמשת למשלוח דיוורים אלה באמצעות מילוי ושליחה של טופס זה, או לחילופין באמצעות כניסה לאזור האישי שלך <a href='https://online.maccabi4u.co.il/' target='_block'>במכבי online</a>  או בפניה בכתב לכתובת: דיוור ישיר, חטיבת שירות ושיווק, רח' המרד 27, תל אביב. ",
  error:{
    title:'לצערנו לא ניתן להשלים את הפעולה',
    text:'ניתן ליצור קשר עם מוקד מכבי ללא הפסקה ב*3555',
    btnText:'הבנתי,אנסה שוב מאוחר יותר'
  }
};

export const ENGLISH = {
  page_title: "Request to Be Removed from Maccabi’s Direct Mailing Lists",
  member_id: "ID Number",
  member_id_placeholder: "ID no. to be removed",
  member_code: "Citizenship Code",
  required_validation: "Please Enter ID Number",
  validation: "Incorrect ID Number",
  first_line: "Please remove me from the following direct mailing lists",
  second_Line: "You can select multiple options",
  children:
    "Please also remove my minor children from the direct mailing lists",
  button: "Submit",
  required_select_all:"At least one type of mailing must be selected",
  disclaimer:"This is a service addressing by direct mailing from the Maccabi Healthcare Services database, depending on the information received from you as an insured.  If you are not interested in receiving messages as part of direct mailing services, in general or of a particular type, you can request that we remove your details from the mailing list used for sending these direct mailings by completing and submitting this form, or alternatively by logging in to your personal area on <a href='https://online.maccabi4u.co.il/' target='_block'>Maccabi Online</a>  , or by writing to the following address: Direct Mailing, Service and Marketing Division, 27 Hamered St., Tel Aviv.",
  error:{
    title:'Unfortunately the operation could not be completed',
    text:'You can contact Moked Maccabi at *3555',
    btnText:'Got it, I will try again later'
  }
};

export const CATEGORIES = 
   [{
        isMultiple: true,
        groupCode: 300,
        serviseCode: [15,30],
        textHebrew: 'תזכורות והמלצות לרפואה מונעת',
        textEnglish: 'Preventative medicine reminders and recommendations'
    },
    {
        isMultiple: false,
        groupCode: 700,
        serviseCode: [211],
        textHebrew: 'מידע בנושאים בריאותיים ושירותים מותאמים אישית',
        textEnglish: 'Information about health issues and personalized services'
    },
    {
        isMultiple: false,
        groupCode: 4,
        serviseCode: [800],
        textHebrew: 'מידע שיווקי על שירותים ומוצרים במכבי',
        textEnglish: 'Marketing information about Maccabi’s services and products'
    },
    {
        isMultiple: false,
        groupCode: 100,
        serviseCode: [91],
        textHebrew: 'טיפת חלב - תזכורת לביקורים תקופתיים',
        textEnglish: '"Tipat Halav" - Periodic visits reminder'
    },
    {
      isMultiple: false,
      groupCode: 200,
      serviseCode: [35],
      textHebrew: 'שאלונים רפואיים',
      textEnglish: 'Medical questionnaires'
  }];

export const CHILDREN = {
    textHebrew: "מבקש/ת להסיר מרשימת הדיוור גם את ילדיי הקטינים",
    textEnglish: "Please also remove my minor children from the direct mailing lists"
};

export const CHECH_ALL = {
    textHebrew: "בחירת כל סוגי הדיוור",
    textEnglish: "Select all"
};

export const LOG = {
  log_element_id: 100,
  log_action_id: 1000,
  log_page: "unl.maccabi4u.co.il",
  variables: null,
  response_time: 0,
  element_phrase: "טעינת מסך",
  page_phrase: "בקשה להסרה מקבלת דיוורים ישירים ממכבי",
  module_id: 6408,
  page_id: 6407,
  action_type:"Open"
};





