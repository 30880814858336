import React, { useState, useEffect } from "react";

import envelope from "./assets/envelope.png";
import web from "./assets/web.png";
import Header from "./components/Header/Header";
import Inputs from "./components/Inputs/Inputs";
import Success from "./components/Success/Success";
import Error from "./components/Error/Error";
import Checkbox from "./components/Checkbox/Checkbox";
import { ENGLISH, HEBREW, CATEGORIES, CHILDREN, LOG } from "./constants";
import "./App.css";

import { notificationRemove, getServerName }  from "./lib/webAPICall";
import { insertCentralizedLog } from "./lib/log";

function App() {
  //const [isCheckAll, setIsCheckedAll] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);

  const [isCheckChildren, setIsCheckedChildren] = useState(false);
  const [memberCode, setMemberCode] = useState("0");
  const [memberId, setMemberId] = useState("");
  const [isMemberIdValid, setIsMemberIdValid] = useState(true);
  const [language, setLanguage] = useState("עברית");
  const [isCategoriesValid, setIsCategoriesValid] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [watermark, setWatermark] = useState('');

  useEffect(() => {
    document.title = language === "עברית" ? "מכבי" : "A Request for removal from direct mailings from Maccabi"
 }, [language]);

  useEffect(() => {
    async function getWatermark(){
      const serverName = await getServerName();
      setWatermark(serverName);
    }
    getWatermark();
  }, []);

  useEffect(() => {

    const { d } = getQueryString();//person_id_cds
    d && insertCentralizedLog(memberCode, d, LOG);
    setCategoriesList(selectCategory(false));
    
  }, [memberCode]);

  useEffect(() => {
  }, [categoriesList]);

  useEffect(() => {
  }, [memberCode]);

  useEffect(() => {
  }, [memberId]);

  const selectCategory = (isCheckAll) => {
    let _categories = [];

    CATEGORIES.map((category, index) => {
      category.isChecked = isCheckAll;
      return _categories.push(category);
    });

    return _categories;
  };

  const onChecked = (item) => {
    let categories = [...categoriesList];
  
    const index = categories.findIndex(
      (category) => category.serviseCode === item.serviseCode
    );
    categories[index].isChecked = !categories[index].isChecked;

    // let categoriesChecked = categories.filter(item => item.isChecked);
    // categoriesChecked.length === categories.length ? setIsCheckedAll(true) : setIsCheckedAll(false);

    setCategoriesList(() => {
      return categories;
    });
  
    setIsCategoriesValid(true);
  };

  // const onCheckedAll = () => {
  //   setIsCheckedAll(!isCheckAll);
  //   selectCategory(!isCheckAll);
  //   setIsCategoriesValid(true);
  // };

  const onCheckedChildren = () => {
    setIsCheckedChildren(!isCheckChildren);
  };

  const getQueryString = () => {
    let params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(_, key, value) {
        params[key] = value;
    });

    return params;
};

const buildParams = (selectedCategories) => {
  let groups = [];

  selectedCategories.forEach((category) => {
    let serviseCode = [];
    category.serviseCode.forEach((service) => {
      serviseCode.push({ service_code: service });
    });

    groups.push({
      group_code: category.groupCode,
      services: serviseCode,
    });
  });

  const { d } = getQueryString();//person_id_cds
  const params = {
    remove_minors_underneath: isCheckChildren,
    person_id_cds: d && parseInt(d), //28525539,
    groups: groups,
  };

  return params;
};

  const onSend = async() => {

    let selectedCategories = CATEGORIES.filter((item) => item.isChecked);
    setIsCategoriesValid(selectedCategories.length !== 0);
    memberId.length === 0 && setIsMemberIdValid(false);

    const isValid = selectedCategories.length !== 0 && memberId.length !== 0 && isMemberIdValid;

    if (isValid) {
     
      const result = notificationRemove(buildParams(selectedCategories), memberCode, memberId);
      if (result) setIsSubmit(result);
      else setIsError(result);

    }

  };

  const onSwichLanguages = () => {
    language === "עברית" ? setLanguage("English") : setLanguage("עברית");
  };

  const getLanguage = language === "עברית" ? HEBREW : ENGLISH;


  return (
    <div className="App">
      <Header language={language} />
      <div className="mainContent">
        <div className={`card  ${language === "עברית" ? "heb" : "eng"}`}>
          <div className={isSubmit ? "hide" : "show"}>
            <button onClick={onSwichLanguages} className="buttonWeb">
              <img src={web} alt="language" className="web" />
              <span>{language === "עברית" ? "English" : "עברית"}</span>
            </button> 

           {watermark !== 'Production' && <div className="watermark">{watermark}</div>}
            
            <img src={envelope} alt="" className="envelope" />
            <h1 className="titleHebrew">{getLanguage.page_title}</h1>
            <div className="disclaimer" dangerouslySetInnerHTML={{ __html: getLanguage.disclaimer }}></div>
            <Inputs
              memberCode={memberCode}
              memberId={memberId}
              isMemberIdValid={isMemberIdValid}
              setIsMemberIdValid={setIsMemberIdValid}
              setMemberCode={setMemberCode}
              setMemberId={setMemberId}
              getLanguage={getLanguage}
            />
            <div className="wrapSelect">
              <div className="firstText">{getLanguage.first_line}</div>
              <div className="secondText">{getLanguage.second_Line}</div>
            </div>
            <div className="wrapCheckboxSection">
              {/* <Checkbox
                item={CHECH_ALL}
                isChecked={isCheckAll}
                setIsChecked={onCheckedAll}
                language={language}
                isCategoriesValid={isCategoriesValid}
              /> */}

              {categoriesList.map((category, index) => {
                return (
                  <Checkbox
                    key={index}
                    item={category}
                    isChecked={category.isChecked}
                    setIsChecked={onChecked}
                    language={language}
                    isCategoriesValid={isCategoriesValid}
                  />
                );
              })}
              <div
                className={
                  isCategoriesValid ? "hideErrorCategories" : "errorCategories"
                }
              >
                {getLanguage.required_select_all}
              </div>
            </div>

            <div className="wrapChildren">
              <Checkbox
                item={CHILDREN}
                isChecked={isCheckChildren}
                setIsChecked={onCheckedChildren}
                language={language}
              />
            </div>

            <div className="wrapSubmit">
              <button className="submit" onClick={onSend}>
                {getLanguage.button}
              </button>
            </div>
          </div>
          <Success isValid={isSubmit} language={language} />
          <Error isValid={isError} language={getLanguage.error} onToggle={setIsError}/>
        </div>
      </div>
    </div>
  );
}

export default App;
