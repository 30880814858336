import React from "react";
import "./Checkbox.css";

function Checkbox(props) {
  //   const [isChecked, setIsChecked] = useState(false);

  const { item, isChecked, setIsChecked, language, isCategoriesValid } = props;

 // console.log("Checkbox:", isChecked);
  //   useEffect(() => {
  //     setIsChecked(false);
  //   }, []);

  const toggleCheck = () => {
   // console.log("toggleCheck-isChecked: ", isChecked);
    setIsChecked(item);
  };

  return (
    <label className={`customControlLable ${!isCategoriesValid && "error"}`}>
      <input
        type="checkbox"
        checked={isChecked}
        className="customControlInput"
        onChange={toggleCheck}
      />
      <span className="checkmark"></span>
      <div>
        <div className="hebrewCheckbox">
          {language === "עברית" ? item.textHebrew : item.textEnglish}
        </div>
      </div>
    </label>
  );
}
export default Checkbox;
